import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { helper, fromKeyToText, format } from 'tools'

let el

class GenDesc extends Component {
    constructor ( props ) {
        super( props )
        el = this
    }

    get = ( key ) => this.fix( helper.extract( el.props.product, key ) )

    todo = ( key ) => 'TODO:' + fromKeyToText( key )

    forceUpper = ( str ) =>
        {
            let product = el.props.product,
                exclusions = {
                    'resin art': 'Resin Art',
                    'www. Livemaster. Ru': 'www.livemaster.ru'
                }

            str = str.replace( new RegExp( product.name.toLowerCase(), 'g' ), product.name )

            Object.keys( exclusions ).map( key => {
                str = str.replace( new RegExp( key, 'g' ), exclusions[key] )
            })

            return str
        }

    fix = ( str ) =>
        {
            if ( Array.isArray( str ) ) {
                return str.map( line => this.fix( line ) )
            }

            if ( typeof str !== 'string' ) {
                return str
            }

            return this.forceUpper(
                    str.trim()
                        .toLowerCase()
                        .replace( / *\. */g, '.' )
                        .replace( / *, */g, ', ' )
                        .replace( /(,|и) древесины/g, '$1' )
                        .replace( / +/g, ' ' )

                        .split( '\.' )
                        .map( p => format.capitalize( p ) )
                        .join( '. ')

                        .split( '«' )
                        .map( p => format.capitalize( p ) )
                        .join( '«' )

                        .replace( /\. _/g, '._' )
                        .replace( /\. –/g, '.–' )
                    )
        }

    render () {
        return (
            <div>
                <p>{this.get( 'maindesc' )}</p>
                <p>{this.get( 'is:state' )}</p>
                <p>{this.get( 'original' )}</p>

                    <p>{this.get( 'separator' )}</p>

                <p>
                    {this.get( 'line_weight' )}
                    <br/>
                    {this.get( 'line_size' )}
                    <br/>
                </p>

                <p>{this.get( 'refine' )}</p>
                <p>{this.get( 'furniture' )}</p>

                    <p>{this.get( 'separator' )}</p>

                <p>{this.get( 'collections' )}</p>
                <p>{this.get( 'feedbacks')}</p>
                <p>{this.get( 'descfooter' )}</p>

                    <p>{this.get( 'separator' )}</p>

                <div>{this.get( 'matdesc' )}</div>
            </div>
        )
    }
}

GenDesc.propTypes = {
    product: PropTypes.object
}

export default GenDesc
