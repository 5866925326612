import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Empty }  from 'antd'
import { Center } from 'components'

let el

class NoSearch extends Component {
    constructor ( props ) {
        super(props)
        el = this
    }

    render () {
        if ( !el.props.search ) {
            return (
                <Center>
                    <Empty
                        description={
                            <em>
                                <strong>Напиши что-нибудь в поле выше</strong><br/>
                                <span>Можно несколько слов, разделенных пробелом.</span>
                            </em>
                        }
                    />
                </Center>
            )
        }

        return null
    }
}

NoSearch.propTypes = {
    search: PropTypes.string
}

export default NoSearch
