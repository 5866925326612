import React, { Component } from 'react'
import { Layout, Table, Button, Input, Row, Col } from 'antd'
import DownloadLink from "react-download-link"

import { favList } from 'tools'

const { Content, Footer, Header } = Layout

class Page extends Component {

    constructor ( props ) {
        super( props )

        this.state = {
            newStr: ''
        }

        this.columns = [
            {
                title: 'Название',
                dataIndex: 'variant',
                key: 'variant'
            },
            {
                title: '',
                key: 'remove',
                width: 40,
                render: ( text, record ) => (
                    <Button
                        shape = "circle"
                        type  = "danger"
                        icon  = "delete"
                        onClick = { this.delete( record ) }
                    />
                )
            }
        ]
    }

    add = ( str ) => {
        favList.add( str )
        this.setState({ newStr: '' })
        this.forceUpdate()
    }

    tryMulti = ( event ) => {
        event.preventDefault()
        let data = event.clipboardData.getData( 'text/plain' ).split( /(\r\n|\r|\n)/ )

        if ( data.length ) {
            data.map( line => this.add( line ) )
        }

        return false
    }

    delete = ( record ) => {
        return e => {
            e.preventDefault()
            favList.removeAt( record.key )
            this.forceUpdate()
        }
    }

    formatted = () => {
        let res = []

        favList.get().map( ( item, index ) => {
            res.push({
                key: index,
                variant: item
            })
        })

        return res
    }

    filename = () => {
        let today = new Date()
        return [ 'ki-names', today.getDate(), today.getMonth() + 1, today.getFullYear() ].join( '-' ) + '.txt'
    }

    export = () => {
        return favList.get().join( "\r\n" )
    }

    updateNew = ( e ) => {
        this.setState({
            newStr: e.target.value
        })
    }

    render () {
        return (
            <Layout>
                <Header>
                    <Row>
                        <Col span={18}>
                            {favList.get().length} понравившихся
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <DownloadLink
                                className="ant-btn ant-btn-lg"
                                filename={this.filename()}
                                exportFile={this.export}
                                style={{}}
                                label="Скачать файлом"
                            />
                        </Col>
                    </Row>
                </Header>
                <Content style={{ backgroundColor: '#fff' }}>
                    <Table
                        showHeader = {false}
                        pagination = {false}
                        columns={this.columns}
                        dataSource={this.formatted()}
                    />
                </Content>
                <Footer>
                    <Row gutter={10}>
                        <Col span={24}>
                            <Input.Search
                                placeholder="Название"
                                enterButton="Добавить"
                                size="large"
                                value={this.state.newStr}
                                onChange={this.updateNew}
                                onSearch={this.add}
                                onPaste={this.tryMulti}
                            />
                        </Col>
                    </Row>
                </Footer>
            </Layout>
        )
    }
}

export default {
    index: 10,
    key: 'store',
    title: 'Сохранённые названия',
    DOM: Page
}
