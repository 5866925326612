import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Empty, List, ListItem } from 'antd'
import { Center, GenDesc } from 'components'
import { helper } from 'tools'

let templates = [
        'title',
        'state',
        'price'
    ]

class GeneratorView extends Component {

    constructor ( props ) {
        super( props )

        this.state = {
            rows: this.getRows()
        }
    }

    componentDidUpdate ( prevProps ) {
        if ( prevProps.product !== this.props.product ) {
            this.createRows()
        }
    }

    getRows = () => {
        const product = this.props.product,
            rows = templates.map( key => helper.get( product, key ) )

        rows.push({
            title: 'Описание',
            text: <GenDesc product={product} />
        })

        return rows
    }

    createRows = () => {
        this.setState({
            rows: this.getRows()
        })
    }

    render () {
        const product = this.props.product

        return (
            <div className="inset-wrap">
                {!product.name && (
                    <Center>
                        <Empty description={
                                <span>Сначала нужно выбрать имя</span>
                            }
                        />
                    </Center>
                )}
                { product.name && (
                    <div>
                        <List
                            itemLayout="horizontal"
                            dataSource={this.state.rows}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={item.title}
                                        description={item.text}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                )}
            </div>
        )
    }
}

GeneratorView.propTypes = {
    product: PropTypes.object
}

export default GeneratorView
