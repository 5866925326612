import React from 'react'

import { titles, contents } from './helpers'
import { format, fromKeyToText, everyDescription, enums } from 'tools'

const helper = {}

helper.get = ( product, key ) => {
    return {
        title: titles[key],
        text: helper.extract( product, key )
    }
}

helper.handlers = ( product ) => {
    const exclude = [ 'handlers', 'get', 'extract' ],
        res = {}

    Object.keys( helper ).map( func => {
        if ( exclude.indexOf( func ) < 0 ) {
            res[func] = helper[func]( product )
        }
    })

    return res
}

helper.extract = ( product, key ) => {
    key = key.replace( /:/g, '_' )

    if ( Object.keys( contents ).indexOf( key ) > -1 ) {
        return contents[key]( product, helper.handlers( product ) )
    } else {
        return helper.text( product )( key )
    }
}

helper.fromkey = () => {
    return ( key, variant ) => fromKeyToText( key, variant )
}

helper.text = ( product ) => {
    return ( key, short ) => fromKeyToText( product[key], short ? 's' : 'n' )
}

helper.adj = ( product ) => {
    return ( key, short ) => fromKeyToText( product[key], short ? 'sa' : 'a' )
}

helper.related = ( product ) => {
    return ( key, short ) => fromKeyToText( product[key], short ? 'sr' : 'r' )
}

helper.join = ( product ) => {
    return ( str ) => (
        helper.many( product )( str ).join( '' )
    )
}

helper.ifelse = ( product ) => {
    return ( test, success, fail ) => {
        fail = fail || ''
        return test() ? success : fail
    }
}

helper.multi = ( product ) => {
    return ( key ) => enums.multi.indexOf( product[key][0] ) > -1
}

helper.one = ( product ) => {
    return ( key, success, fail ) =>
        helper.ifelse( product )(
            () => product[key] && product[key].length === 1 && !helper.multi( product )( key ),
            success,
            fail
        )
}

helper.ifnotonly = ( product ) => {
    return ( key, v, success, fail ) =>
        helper.ifelse( product )(
            () => ( product[key] && ( product[key].length > 1 || product[key][0] !== v ) ),
            success,
            fail
        )
}

helper.only = ( product ) => {
    return ( key, v, success, fail ) =>
        helper.ifelse( product )(
            () => ( product[key] && product[key].length === 1 && product[key][0] === v ),
            success,
            fail
        )
}

helper.inc = ( product ) => {
    return ( key, v ) => product[key].indexOf( v ) > -1
}

helper.has = ( product ) => {
    return ( key, v, success, fail ) =>
        helper.ifelse( product )(
            helper.inc( product )( key, v ),
            success,
            fail
        )
}

helper.anyof  = ( product ) => {
    return ( key, vars, success, fail ) =>
        helper.ifelse( product )(
            () => product[key].filter( item => vars.indexOf( item ) > -1 ).length > 0,
            success,
            fail
        )
}

helper.many = ( product ) => {
    return ( list ) => (
        list.replace( / +/g, ' ' )
            .split( ' ' )
            .map( key => helper.extract( product, key.replace( /:/g, '_' ) ) )
    )
}

helper.paragraphs = ( product ) => {
    return ( arr ) => arr.map( ( line, index ) => <p key={'p' + index}>{line}</p> )
}

helper.prop = ( product ) => {
    return ( key ) => helper.get( product, key ).text
}

helper.test = ( product ) => {
    return ( keys, success, fail ) =>
        helper.ifelse( product )(
            () => {
                let test = keys.split( ' ' ).map( key => {
                    let prop = product[key]

                    return Array.isArray( prop )
                        ? prop.length > 0
                        : !!prop
                })

                return !test.includes( false )
            },
            success,
            fail
        )
}

helper.list = ( product ) => {
    return ( key, sep, last ) =>
        format.uberlist(
            product[key],
            {
                p: 'n',
                separator: sep || ', ',
                last: last || ' и '
            }
        )
}

helper.complex = ( product ) => {
    return ( key, pre, post, short ) => {
        let res = ''

        if ( !product[key] ) { return '' }

        pre  = pre  || ''
        post = post || ''

        if ( Array.isArray( product[key] ) ) {
            res = format.uberlist(
                product[key],
                {
                    p: short ? 'sr' : 'r',
                    separator: ', ',
                    last: ' и '
                }
            )
        } else {
            res = product[key]
        }

        return res
            ? pre + res + post
            : ''
    }
}

helper.ifis = ( product ) => {
    return ( key, items ) => {
        let def = items['_def'] || ''

        return Object.keys( items ).reduce( ( a, v ) => {
            return ( helper.is( product )( key, v ) ) ? items[v] : a
        }, def )
    }
}

helper.is = ( product ) => {
    return ( key, v ) => product[key] === v
}

helper.numeric = ( product ) => {
    return ( key, vars ) => {
        let value = Math.floor( product[key] ).toString(),
            end = parseInt( value.substr( -1 ) )

        if ( value >= 10 && value < 15 || value % 10 === 0 ) {
            return vars[2]
        }

        if ( end <= 1 ) {
            return vars[0]
        }

        if ( end < 5 ) {
            return vars[1]
        }

        return vars[2]
    }
}

helper.desc = ( product ) => {
    return ( key ) => product[key].map( item => everyDescription( item ) )
}

helper.autop = ( product ) => {
    return ( str ) => {
        let res = [],
            lines = str.split( '\n' )

        lines.map( ( line, index ) => { res.push( line ); res.push(<br key={'b' + index} />) } )

        return res
    }
}

export default helper
