import React, { Component } from 'react'

import './addon.scss'

class AddOn extends Component {
    render () {
        return (
            <span className = "input-addon">
                    {this.props.children}
            </span>
        )
    }
}

export default AddOn
