import { format } from 'tools'

const LS_ITEM_NAME = '_ki_helper_saved_list'

let savedList = {
    _list: null,

    load: () =>
        {
            let raw = localStorage.getItem( LS_ITEM_NAME )
            ;( !raw ) && ( raw = '{}' )
            savedList._list = JSON.parse( raw )
        },

    get: () =>
        {
            if ( !savedList._list ) {
                savedList.load()
            }

            return savedList._list
        },

    update: ( product ) =>
        {
            if ( !product.id ) {
                product.id = format.genID()
            }

            console.log( product )

            savedList._list[product.id] = product
            savedList.save()
        },

    save: () =>
        {
            localStorage.setItem( LS_ITEM_NAME, JSON.stringify( savedList._list ) )
        }
}


export default savedList
