import { fromKeyToText } from 'tools'

const format = {
    capitalize: str =>
        {
            if ( !str ) return str

            let offset = 0,
                pre = ''

            while ( str[offset] === ' ' ) { offset++ }
            if ( offset > 0 ) { pre = str.substr( 0, offset ) }

            return pre + str[offset].toUpperCase() + str.substr(offset + 1)
        },

    uberlist: ( arr, cfg ) =>
        {
            let { p, separator, last, lower } = cfg

            p = p || 'n'
            separator = separator || ' '
            last = last || separator
            lower = lower || false

            return arr.map( m => lower ? fromKeyToText( m, p ).toLowerCase() : fromKeyToText( m, p ) )
                        .join( ', ' )
                        .reverse()
                        .replace( separator.reverse(), last.reverse() )
                        .reverse()
        },


    genID: () => '_' + Math.random().toString(36).substr(2, 9),

    type: ( raw, forced ) =>
        {
            forced = forced || false

            let split = raw.split( ':' ),
                rkey = split[0],
                type = ( forced ) ? forced : split[1],
                override = false,
                def = undefined

            if ( type.indexOf( '/') > -1 ) {
                split = type.split( '/' )
                type = split[0]
                override = split[1]
            }

            if ( override && override.indexOf( '=' ) > -1 ) {
                split = override.split( '=' )
                override = split[0]
                def = split[1]
            }

            if ( type.indexOf( '=' ) > -1 ) {
                split = type.split( '=' )
                type = split[0]
                def = split[1]
            }

            return { rkey, type, override, def }
        },

    extract: ( data, key, forceType ) =>
        {
            var items = {},
                defs = {
                    'string': '',
                    'str': '',

                    'int': 0,
                    'integer': 0,
                    'number': 0,
                    'float': 0.0,

                    'boolean': false,
                    'bool': false,

                    array: [],
                    object: null
                },
                fdata

            if ( !data[key] ) { return undefined }

            fdata = ( Array.isArray( data[key] ) )
                ? data[key]
                : data[key].trim().split( ' ' )

            fdata.map( raw => {
                let { rkey, type, override, def } = format.type( raw, forceType )

                if ( def !== undefined ) {
                    items[rkey] = def
                } else {
                    items[rkey] = ( Object.keys( defs ).indexOf( type ) > -1 )
                         ? defs[type]
                         : format.extract( data, type, override )
                 }
            })

            return items
        }
}

export default format
