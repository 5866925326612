import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Center, Spinner } from 'components'

class Loading extends Component {
    render () {
        if ( this.props.show ) {
            return (
                <Center>
                    <Spinner type={this.props.type} />
                    <em>Загружаю...</em>
                </Center>
            )
        }

        return null
    }
}

Loading.propTypes = {
    show: PropTypes.bool,
    type: PropTypes.string
}

export default Loading
