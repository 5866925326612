import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
    Form,
    Select,
    Collapse,
    InputNumber,
    Input,
    Checkbox
} from 'antd'

import { AddOn } from 'components'
import { enums, fromKeyToText, format } from 'tools'

const { Option } = Select,
      { Panel } = Collapse

class ConfigForm extends Component {

    update = ( data ) => this.props.onChange( data )

    onChange = ( key ) => {
        return val => {
            let args = {}

            args[key] = ( val.target )
                ? (
                    val.target.value
                        ? val.target.value
                        : val.target.checked
                  )
                : args[key] = val

            this.update( args )
        }
    }

    enum = ( list ) => {
        return list.map( item => (
            <Option key={item} value={item}>
                {format.capitalize( fromKeyToText( item ) )}
            </Option>
        ))
    }

    render () {
        const product = this.props.product

        if ( product.name ) {
            return (
                <div className = "innerForm">

                    <Form.Item label = "Тип">
                        <Select
                            onChange = {this.onChange( 'type' )}
                            value={product.type}
                        >
                            {this.enum( enums.types )}
                        </Select>
                    </Form.Item>

                    <Form.Item label = "Статус">
                        <Select
                            value = {product.state}
                            onChange = {this.onChange( 'state' )}
                        >
                            {this.enum( enums.states )}
                        </Select>
                    </Form.Item>

                    <Form.Item label = "Цена">
                        <InputNumber
                            value = {product.price}
                            min          = {0}
                            max          = {1000000}
                            onChange     = {this.onChange( 'price' )}
                        />
                        <AddOn>руб.</AddOn>
                    </Form.Item>

                    <Collapse>
                        <Panel
                            header = "Характеристики"
                            key = "1"
                        >

                            <Form.Item label = "Вес">
                                <InputNumber
                                    value = {product.weight}
                                    min          = {0}
                                    max          = {1000}
                                    step         = {0.01}
                                    onChange     = {this.onChange( 'weight' )}
                                />
                                <AddOn>грамм</AddOn>
                            </Form.Item>

                            { product.type !== 'ring' && (
                                <Form.Item label = "Размеры">
                                    <Input
                                        value = {product.size}
                                        placeholder  = "В свободной форме"
                                        onChange     = {this.onChange( 'size' )}
                                    />
                                </Form.Item>
                            )}

                            { product.type === 'ring' && (
                                <Form.Item label = "Размеры кольца">
                                    <InputNumber
                                        value = {product.ringsize}
                                        min          = {10}
                                        max          = {30}
                                        step         = {0.5}
                                        onChange     = {this.onChange( 'ringsize' )}
                                    />
                                </Form.Item>
                            )}

                        </Panel>

                        <Panel
                            header = "Материалы"
                            key = "2"
                        >
                            <Form.Item label = "Основные">
                                <Select
                                    mode        = "multiple"
                                    placeholder = "Можно несколько"
                                    onChange    = {this.onChange( 'materials' )}
                                    value       = {product.materials}
                                >
                                    {this.enum( enums.materials )}
                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <Checkbox onChange={this.onChange( 'joinwood' )}>Объединять деревья</Checkbox>
                            </Form.Item>

                            <Form.Item label = "Вставки и подобное">
                                <Select
                                    mode        = "multiple"
                                    placeholder = "Можно несколько"
                                    onChange    = {this.onChange( 'inserts' )}
                                    value       = {product.inserts}
                                >
                                    {this.enum( enums.materials )}
                                </Select>
                            </Form.Item>

                            <Form.Item label = "Дополнительные">
                                <Select
                                    mode        = "multiple"
                                    placeholder = "Можно несколько"
                                    onChange    = {this.onChange( 'addmats' )}
                                    value       = {product.addmats}
                                >
                                    {this.enum( enums.materials )}
                                </Select>
                            </Form.Item>
                        </Panel>

                        <Panel
                            header = "Обработка"
                            key = "3"
                        >
                            <Form.Item label = "Техника">
                                <Select
                                    mode        = "multiple"
                                    placeholder = "Можно несколько"
                                    onChange    = {this.onChange( 'technic' )}
                                    value       = {product.technic}
                                >
                                    {this.enum( enums.technics )}
                                </Select>
                            </Form.Item>

                            <Form.Item label = "Покраска">
                                <Select
                                    mode        = "multiple"
                                    placeholder = "Можно несколько"
                                    onChange    = {this.onChange( 'paint' )}
                                    value       = {product.paint}
                                >
                                    {this.enum( enums.materials )}
                                </Select>
                            </Form.Item>

                            <Form.Item label = "Покрытие">
                                <Select
                                    mode        = "multiple"
                                    placeholder = "Можно несколько"
                                    onChange    = {this.onChange( 'overlay' )}
                                    value       = {product.overlay}
                                >
                                    {this.enum( enums.materials )}
                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <Checkbox onChange={this.onChange( 'polished' )}>Полировано</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox onChange={this.onChange( 'varnished' )}>Покрыто лаком</Checkbox>
                            </Form.Item>
                        </Panel>

                        <Panel
                            header = "Разное"
                            key = "4"
                        >
                            <Form.Item>
                                <Checkbox onChange={this.onChange( 'handmade' )}>Ручная работа</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox onChange={this.onChange( 'glow' )}>Светится в темноте</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox onChange={this.onChange( 'waterproof' )}>Защита от воды</Checkbox>
                            </Form.Item>
                        </Panel>
                    </Collapse>

                </div>
            )
        }

        return null
    }
}

ConfigForm.propTypes = {
    product: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

export default ConfigForm
