const LS_ITEM_NAME = '_ki_helper_fav_list'

let favList = {
    _list: null,

    load: () =>
        {
            let raw = localStorage.getItem( LS_ITEM_NAME )
            ;( !raw ) && ( raw = '[]' )
            favList._list = JSON.parse( raw )
            favList.clean()
        },

    clean: () =>
        {
            favList._list = favList._list.filter( item => item.trim() )
        },

    get: () =>
        {
            if ( !favList._list ) {
                favList.load()
            }

            return favList._list
        },

    has: ( str ) =>
        {
            let low = str.toLowerCase(),
                arrlow = favList.get().map( s => s.toLowerCase() )

            return ( arrlow.indexOf( low ) > -1 )
        },

    add: ( str ) =>
        {
            if ( str.trim() && !favList.has( str ) ) {
                favList._list.push( str )
                favList.save()
            }
        },

    removeAt:  ( index ) =>
        {
            favList._list.splice( index, 1 )
            favList.save()
        },

    save: () =>
        {
            localStorage.setItem( LS_ITEM_NAME, JSON.stringify( favList._list ) )
        }
}


export default favList
