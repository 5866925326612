import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import { Alert } from 'antd'
import { Variant, NoSearch, Loading } from 'components'

import { superState } from 'tools'

import './variants.scss'

const api = 'https://api.wordassociations.net/associations/v1.0/json/search',
    cfg = {
        apiKey: '251265b6-9ae3-42d8-8957-bbb02d82800c',
        lang: 'ru',
        type: 'response',
        limit: 300
    }

let el

class Variants extends Component {
    constructor(props) {
        super(props)

        superState.init( this, {
            sub: 'noun:bool adjective:bool',
            vars: 'loading:sub result:sub/object error:object'
        })

        el = this
    }

    componentDidUpdate ( prevProps ) {
        if (this.props.search !== prevProps.search) {
            this.loadAll( this.props.search )
        }
    }

    loadAll ( str ) {
        el.update({
            loading: true,
            error: null
        })

        el.load( str, 'noun' )
        el.load( str, 'adjective' )
    }

    request ( url, params, success, error ) {
        axios
            .get( url, params )
            .then( success )
            .catch( error )
    }

    done ( pos, result ) {
        el.deepUpdate( pos, { loading: false, result: result } )
    }

    error ( pos, error ) {
        el.deepUpdate( pos, { loading: false }, { error } )
    }

    load ( str, pos ) {
        const params = {
                apikey: cfg.apiKey,
                lang: cfg.lang,
                type: cfg.type,
                limit: cfg.limit,
                pos: pos,
                text: str
            }

        el.request(
            api,
            { params },
            res => el.done( pos, res.data.response ),
            error => el.error( pos, error )
        )
    }

    render() {
        return (
            <div className="content">
                <NoSearch search={el.props.search} />
                <Loading show={el.is( 'loading' )} type="dark block" />

                { this.state.error && (
                    <Alert
                        message="Something went wrong"
                        description={this.state.error}
                        type="error"
                    />
                )}

                { ( this.state.result.noun && this.state.result.adjective && !el.is( 'loading' ) ) && (
                    <Variant
                        adjectives = {this.state.result.adjective}
                        nouns = {this.state.result.noun}
                    />
                )}
            </div>
        )
    }
}

Variants.propTypes = {
    search: PropTypes.string
}

export default Variants
