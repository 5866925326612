const lastUIState = {},
    LUIS_ITEM_NAME = '_ki_helper_last_ui_state',
    _default_state = JSON.stringify({
        page: 'request'
    })

loadState()

function loadState () {
    let raw  = localStorage.getItem( LUIS_ITEM_NAME ) || _default_state,
    done = JSON.parse( raw )

    Object.keys( done ).map( key => {
        lastUIState[key] = done[key]
    })

    lastUIState.set = setState
}

function setState ( prop, value ) {
    let newState = Object.assign({}, lastUIState)

    delete newState.set
    newState[prop] = value
    lastUIState[prop] = value

    localStorage.setItem( LUIS_ITEM_NAME, JSON.stringify( newState ) )
}

export default lastUIState
