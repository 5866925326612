import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
    Form,
    AutoComplete,
    Button,
    Row,
    Col,
    Dropdown,
    Menu
} from 'antd'

import { ConfigForm } from 'components'
import { favList, savedList, config, format } from 'tools'

class GeneratorForm extends Component {
    constructor ( props ) {
        super( props )

        this.state = {
            names: []
        }
    }

    searchForName = ( str ) => {
        let result,
            list = favList.get()

        result = ( str )
            ? list.filter( name => name.toLowerCase().indexOf( str.toLowerCase()) > -1 )
            : list

        ;( str && list.indexOf( str ) < 0 ) && ( result.push( 'Добавить «' + str + '»' ) )

        this.setState({ names: result })
    }

    setName = ( name ) => {
        let rname = name

        if ( name.indexOf( 'Добавить «' ) === 0 ) {
            rname = name.split('«')[1].split('»')[0]
            favList.add( rname )
        }

        this.update({ name: rname })
    }

    update = ( data ) => {
        let newprod = Object.assign( {}, this.props.product )

        Object.keys( data ).map( key => {
            newprod[key] = data[key]
        })

        this.props.onChange( newprod )
    }

    flush = () => {
        let data = format.extract({ prod: config.baseProd }, 'prod' )
        data['id'] = null
        this.update( data )
    }

    loadProd = ( prod ) => {
        return () => this.update( prod )
    }

    loadMenu = () => {
        let items = [],
            raw = savedList.get()

        items.push( <Menu.Item key="new_prod" onClick={this.flush}><b>Сбросить</b></Menu.Item> )
        items.push( <Menu.Divider key="divider" /> )

        Object.values( raw ).map( prod => {
            items.push( <Menu.Item key={prod.id} onClick={this.loadProd( prod )}>{prod.name}</Menu.Item> )
        })

        return (
            <Menu>
                {items}
            </Menu>
        )
    }

    save = () => {
        savedList.update( this.props.product )
    }

    render () {
        return (
            <Form>
                <Row>
                    <Col span={12}>
                        <Dropdown.Button
                            overlay={this.loadMenu}
                            trigger={['click']}
                            disabled={ savedList.get().length < 1 }
                        >
                            Загрузить
                        </Dropdown.Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            style={{ float: 'right' }}
                            disabled={ !this.props.product.name }
                            onClick={this.save}
                        >
                            Сохранить
                        </Button>
                    </Col>
                </Row>
                <Form.Item label="Название">
                    <AutoComplete
                        value        = {this.props.product.name}
                        dataSource   = {this.state.names}
                        onSearch     = {this.searchForName}
                        onSelect     = {this.setName}
                        onChange     = {this.setName}
                        placeholder  = "Выбери или введи название"
                    />
                </Form.Item>
                { this.props.product.name && (
                    <ConfigForm product={this.props.product} onChange={this.update} />
                )}
            </Form>
        )
    }
}

GeneratorForm.propTypes = {
    product: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

export default GeneratorForm
