const translation = {

/*
    Keys
*/

    weight:
        {
            n: 'Вес'
        },

    size:
        {
            n: 'Размеры'
        },

    refine:
        {
            n: 'Обработка'
        },

    furniture:
        {
            n: 'Фурнитура'
        },

    collections:
        {
            n: 'Коллекции'
        },

/*
    Types
*/
    'ring':
        {
            n: 'Кольцо',
            r: 'кольца',
            s: 'Изделие',
            w: 'кольца'
        },
    'pendant':
        {
            n: 'Кулон',
            r: 'кулона',
            s: ' ',
            w: 'кулоны'
        },
    'double':
		{
			n: 'Парные кольца',
            r: 'парных колец',
            s: 'Кольца'
		},

/*
    States
*/
    'ready':
		{
			n: 'Готовый',
            w: 'готовые'
		},
    'example':
		{
			n: 'Для примера'
		},
    'byorder':
		{
			n: 'На заказ'
		},

/*
    Materials
*/
    'wood':
		{
			n: 'дерево',
            r: 'дерева',
            sr: 'дерева',
            w: 'изделия с деревом'
		},
    'pine':
        {
            n: 'сосна',
            r: 'древесины сосны',
            sr: 'сосны'
        },
    'larch':
        {
            n: 'лиственница',
            r: 'древесины лиственницы',
            sr: 'лиственницы'
        },
    'oak':
        {
            n: 'дуб',
            r: 'древесины дуба',
            sr: 'дуба'
        },
    'beech':
        {
            n: 'бук',
            r: 'древесины бука',
            sr: 'бука',
        },
    'venge':
        {
            n: 'венге',
            r: 'древесины венге',
            sr: 'венге'
        },
    'jewresin':
		{
			n: 'ювелирная смола',
            s: 'смола',
            r: 'ювелирной смолы',
            sr: 'смолы',
            w: 'изделия с ювелирной смолой'
		},
    'colorings':
        {
            n: 'цветные пигменты',
            s: 'пигменты',
            r: 'цветных пигментов',
            sr: 'пигментов'
        },
    'metalfurniture':
        {
            n: 'металическая фурнитура',
            r: 'металлической фурнитуры'
        },
    'silverfurniture':
        {
            n: 'серебряная фурнитура',
            r: 'серебряной фурнитуры'
        },
    'leathercord':
        {
            n: 'кожанный шнурок',
            r: 'кожанного шнурка'
        },
    'natural':
        {
            n: 'натуральные материалы',
            r: 'натуральных материалов'
        },

/*
    Technics
*/
    'resinart':
        {
            n: 'Resin Art (рисунок смолой)',
            s: 'Resin Art'
        }
}

function fromKeyToText ( what, variant ) {
    variant = variant || 'n'

    if ( !translation[what] ) {
        return what
    }

    if ( !translation[what][variant] ) {
        return translation[what].n
    }

    return translation[what][variant]
}

export default fromKeyToText
