import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'

import './search.scss'

const AntSearch = Input.Search

let el

class Search extends Component {
    constructor(props) {
        super(props)
        el = this
    }

    render() {
        return (
            <AntSearch
              placeholder="Выбери тему"
              enterButton="Подобрать"
              size="large"
              onSearch={el.props.onSubmit}
            />
        )
    }
}

Search.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default Search
