import React from 'react'
import { format, enums } from 'tools'

export const titles = {
    title: 'Заголовок:',
    type: 'Тип:',
    size: 'Размер:',
    mats: 'Материалы:',
    price: 'Цена:',
    state: 'Статус:'
}

export const contents = {

    /* STRINGS */

    separator: () => '–._.–._.–._.–',//'~ . _ . • ● * { ~ ☼ ~ } * ● • . _ . ~',
    feedbacks: () => 'Отзывы покупателей: https://www.livemaster.ru/olyaki/feedbacks',

    principles: ( product, h ) => h.autop(
            `Принципы работы:
                - Все изделия выполнены вручную
                - Только высококачественные компоненты
                - Индивидуальный подход
                - Гарантии возврата или обмена
                - ...`
        ),

    descfooter: ( product, h ) => h.autop(
            `« Есть вопрос? Спросите мастера, нажав «Сообщение» вверху под аватаркой.
            « Чтобы первыми узнавать о новинках и акциях — подпишитесь на обновления магазина, нажав на «+ Подписаться».`
        ),

    __d:      () => ': ',
    __sp:     () => ' ',
    __dot:    () => '.',
    __comma:  () => ', ',
    __dash:   () => ' — ',
    __br:     () => <br/>,

    _made:     () => 'сделан',
    _show:     () => 'представлен',
    _made2:    () => 'выполнен',
    _handmade: () => 'ручной работы',
    _from:     () => ' из ',

    _tech:    () => ' в техник',
    _weight:  () => 'Вес',
    _size:    () => 'Размер',
    _insert:  () => 'со вставк',
    _add:     () => 'В комплекте',

    _forex:      () => 'для примера',
    _order:      () => 'на заказ',
    _buynow:     () => 'можно купить прямо сейчас',
    _cando:      () => 'но можно изготовить',
    _nocopy:     () => 'точное повторение невозможно',
    _original:   () => 'Оригинальное ',
    _cannotcopy: () => 'и неповторимое',
    _origend:    () => 'украшение, которое подчеркнет вашу индивидуальность.',

    _ready:   ( product, h ) => h.join( 'by:ready ::comma :buynow ::dot' ),
    _example: ( product, h ) => h.join( ':show by:type ::sp :forex ::comma :nocopy ::comma :cando ::sp by:same ::dot' ),
    _byorder: ( product, h ) => h.join( ':made2 by:type ::sp :order ::comma :nocopy ::dot' ),

    /* DiFF VERIATIONS */

    rel_type: ( product, h ) => ' ' + h.related( 'type' ),
    short_type: ( product, h ) => h.text( 'type', true ),

    /* CONDITIONAL */

    is_fully:  ( product, h ) => h.one( 'materials', ' полностью ' ),
    is_tech:   ( product, h ) => h.one( 'technic', 'е', 'ах' ),
    is_insert: ( product, h ) => h.one( 'inserts', 'ой', 'ами' ),

    is_state: ( product, h ) => h.test( 'type', h.join( 'short:type ::sp :' + product.state ) ),
    is_wood: ( product, h ) => h.only( 'materials', 'wood', h.join( 'by:wood ::sp' ) ),

    is_uniq:  ( product, h ) =>
        h.ifis(
            'state',
            {
                'byorder': h.join( ':cannotcopy ::sp' )
            }
        ),

    if_handmade: ( product, h ) => product.handmade
        ? h.join( '::sp :handmade ::sp' )
        : h.join( '::sp not:wood:made ::sp' ),

    not_wood_made: ( product, h ) => h.ifnotonly( 'materials', 'wood', h.join( ':made by:type' ) ),

    /* REPLACES */

    by_wood: ( product, h ) =>
        h.ifis(
            'type',
            {
                'ring': 'Деревянное',
                'double': 'Деревянные',
                '_def': 'Деревянный'
            }
        ),

    by_same: ( product, h ) =>
        h.ifis(
            'type',
            {
                'ring': 'похожее',
                'double': 'похожие',
                '_def': 'похожий'
            }
        ),

    by_ready: ( product, h ) =>
        h.ifis(
            'type',
            {
                'ring': 'готовое',
                'double': 'готовые',
                '_def': 'готовый'
            }
        ),

    by_type: ( product, h ) =>
        h.ifis(
            'type',
            {
                'ring': 'о',
                'double': 'ы',
                '_def': ''
            }
        ),

    by_weight: ( product, h ) =>
        h.numeric(
            'weight',
            [
                'грамм',
                'грамма',
                'грамм'
            ]
        ),

    original: ( product, h ) =>
        h.ifis(
            'state',
            {
                'byorder': '',
                '_def': h.join( ':original is:uniq :origend' )
            }
        ),

    /* BASIC */

    name: ( product )    => product.name,
    type: ( product, h ) => h.text( 'type' ),
    price: ( product ) => product.price + ' руб.',
    weight: ( product, h ) => product.weight + ' ' + h.prop( 'by:weight' ),

    size: ( product, h ) =>
        h.ifis(
            'type',
            {
                'ring': h.test( 'ringsize', ' (' + product.ringsize + ')' ),
                '_def': h.test( 'size', ', ' + product.size )
            }
        ),

    rsize: ( product, h ) =>
        h.ifis(
            'type',
            {
                'ring': h.test( 'ringsize', product.ringsize ),
                '_def': h.test( 'size', product.size )
            }
        ),

    line_weight: ( product, h ) => h.test( 'weight', h.join( ':weight rel:type ::d weight') ),
    line_size: ( product, h ) => h.join( ':size rel:type ::d rsize'),

    first_technic: ( product, h ) => h.test( 'technic', '(' + h.fromkey( product.technic[0], 's' ) + ')' ),

    /* LISTS */

    list_mats:     ( product, h ) => h.complex( 'materials', 'из ' ),
    list_mats_s:   ( product, h ) => h.complex( 'materials', 'из ', '', true ),
    list_addmats:  ( product, h ) => h.list( 'addmats', ', ' ),
    list_technics: ( product, h ) => h.complex( 'technic', '', '' ),
    list_inserts:  ( product, h ) => h.complex( 'inserts' ),

    join_mats: ( product, h ) => h.test( 'joinwood', h.prop( 'joined:mats' ), h.prop( 'list:mats:s' ) ),

    /* COMPLEX */

    title:    ( product, h ) => h.join( 'name ::dash type ::sp join:mats size ::sp first:technic' ),
    made:     ( product, h ) =>
        h.test( 'type materials', h.ifnotonly( 'materials', 'wood', h.join( 'is:fully ::sp list:mats' ) )),

    technic:  ( product, h ) => h.test( 'technic', h.join( ':tech is:tech ::sp list:technics' ) ),
    inserts:  ( product, h ) => h.test( 'inserts', h.join( '::sp :insert is:insert :from list:inserts' ) ),

    matdesc:  ( product, h ) => h.test( 'materials', h.paragraphs( h.desc( 'materials' ) ) ),
    maindesc: ( product, h ) => h.join( 'is:wood type if:handmade made inserts technic ::dot' ),

    refine: ( product, h ) => '',
    furniture: ( product, h ) => h.test( 'addmats', h.join( ':add ::sp list:addmats') ),

    /* SUPER COMPLEX */

    collections: ( product, h ) => {
        let res = []

        Object.keys( enums.collections ).map( ( key, index ) => {
            if ( h.is( 'state', key ) || h.is( 'type', key ) || h.inc( 'materials', key ) ) {
                res.push( 'Другие ' + h.fromkey( key, 'w' ) + ': ' + enums.collections[key] )
                res.push( <br key={'b' + index} /> )
            }
        })

        ;( res.length > 0 ) && ( res.pop() )
        return res
    },

    join_wood: ( product, h ) => {
        let mats = product.materials.filter( mat => enums.woods.indexOf( mat ) < 0 )
        mats.unshift( 'wood' )

        return 'из ' + format.uberlist(
            mats,
            {
                p: 'sr',
                separator: ', ',
                last: ' и '
            }
        )
    },

    joined_mats: ( product, h ) =>
        h.anyof(
            'materials',
            enums.woods,
            h.prop( 'join:wood' ),
            h.prop( 'list:mats:s' )
        )

}
