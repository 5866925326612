import React, { Component } from 'react'
import { Layout } from 'antd'

import { GeneratorForm, GeneratorView } from 'components'
import { superState, config } from 'tools'

const { Sider, Content } = Layout

class Page extends Component {

    constructor ( props ) {
        super( props )

        superState.init( this, {
            product: config.baseProd,
            vars: 'product:product'
        })
    }

    onUpdate = ( product ) => {
        this.update({ product: product })
    }

    render () {
        return (
            <Layout>
                <Content>
                    <GeneratorView
                        product={this.state.product}
                    />
                </Content>
                <Sider width={300} style={{ padding: '10px 16px' }}>
                    <GeneratorForm
                        product  = {this.state.product}
                        onChange = {this.onUpdate}
                    />
                </Sider>
            </Layout>
        )
    }
}

export default {
    index: 20,
    key: 'generator',
    title: 'Генератор описания',
    DOM: Page
}
