const config = {
    baseProd:
        [
            'name:string',
            'type:string',
            'state:string',
            'price:int',

            'materials:array',
            'inserts:array',
            'addmats:array',

            'weight:float',
            'size:string',
            'ringsize:float',

            'handmade:bool',
            'glow:bool',
            'polished:bool',
            'waterproof:bool',
            'joinwood:bool',

            'technic:array',
            'paint:array',
            'overlay:array'
        ]
}

export default config
