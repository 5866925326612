const descriptions = {
    'wood': '',

    'jewresin':
        'Ювелирная эпоксидная смола — это твердая стеклообразная прозрачная масса. Изделия из нее высокопрочные, внешне похожи на стекло. Изделия из смолы легковесны, долговечны и прочны — их невозможно случайно разбить. К сожалению, опасность поцарапать остаётся, поэтому необходим бережный уход.'
}


function everyDescription ( what ) {
    return descriptions[what]
        ? descriptions[what]
        : ''
}

export default everyDescription
