import React, { Component } from 'react'
import { Layout, Tabs } from 'antd'
import * as Pages from 'pages'

import { lastUIState } from 'tools'

import 'App.scss'

const { Content } = Layout
const { TabPane } = Tabs

let app

class App extends Component {

    constructor ( props ) {
        super( props )
        app = this
    }

    componentDidMount () {
        window.addEventListener('load', () => {
            setTimeout(() => document.body.className += ' loaded', 500)
        })
    }

    getContent () {
        return Object.values( Pages )
            .sort( ( a, b ) => {
                if ( a.index === b.index ) { return 0 }
                return ( a.index < b.index ) ? -1 : 1
            })
            .map( page => {
                return ( <TabPane tab={page.title} key={page.key}><page.DOM /></TabPane> )
            })
    }

    onTab ( active ) {
        lastUIState.set( 'page', active )
    }

    render() {
        return (
            <Layout>
                <Content>
                    <Tabs
                        defaultActiveKey={lastUIState.page}
                        tabPosition = "left"
                        tabBarStyle = {{ width: 200 }}
                        onChange = {app.onTab}
                    >
                        {app.getContent()}
                    </Tabs>
                </Content>
            </Layout>
        )
    }
}

export default App
