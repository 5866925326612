import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './spinner.scss'

class Spinner extends Component {
    render () {
        return (
            <div className={"lds-dual-ring " + this.props.type}></div>
        )
    }
}

Spinner.propTypes = {
    type: PropTypes.string
}

export default Spinner
