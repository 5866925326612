import React, { Component } from 'react'
import { Layout, Row, Col } from 'antd';

import { Search, Variants } from 'components'

const { Header, Content } = Layout

class Page extends Component {

    constructor ( props ) {
        super( props )

        this.state = {
            search: ''
        }
    }

    setSearch = ( str ) => {
        this.setState({
            search: str
        })
    }

    render() {
        return (
            <Layout>
                <Header>
                    <Row>
                        <Col span={24}>
                            <Search onSubmit={this.setSearch} />
                        </Col>
                    </Row>
                </Header>
                <Content>
                    <Variants search={this.state.search} />
                </Content>
            </Layout>
        )
    }
}

export default {
    index: 0,
    key: 'request',
    title: 'Подбор названий',
    DOM: Page
}
