import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Icon }    from 'antd'
import { favList } from 'tools'

import './favbutton.scss'

let el

class FavButton extends Component {
    constructor ( props ) {
        super( props )
        el = this
    }

    add () {
        if ( el.props.item ) {
            favList.add( el.props.item )
            el.forceUpdate()
        }
    }

    has ( t, f ) {
        return favList.has( el.props.item ) ? t : f
    }

    cls () {
        return  "fav-button " + el.has( 'exist', '' )
    }

    theme () {
        return el.has( 'filled', 'twoTone' )
    }

    color () {
        return el.has( '#40a9ff', null )
    }

    render () {
        return (
            <div className={el.cls()} onClick={el.add}>
                <Icon type="star" theme={el.theme()} style={{color: el.color()}} />
            </div>
        )
    }
}

FavButton.propTypes = {
    item: PropTypes.string
}

export default FavButton
