import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Button } from 'antd'
import { FavButton } from 'components'

let el

class Variant extends Component {
    constructor ( props ) {
        super( props )

        this.state = {
            adjective: '',
            noun: ''
        }

        el = this
    }

    componentDidMount () {
        this.randomize()
    }

    componentDidUpdate ( prevProps ) {
        if (this.props.adjectives !== prevProps.adjectives || this.props.nouns !== prevProps.nouns ) {
            this.randomize()
        }
    }

    randomize () {
        let n = el.randomizeOne( 'nouns' ),
            a = el.randomizeOne( 'adjectives' ),
            noun = el.getSome( 'noun', n.top, n.sub ),
            adj = el.format( el.getSome( 'adjective', a.top, a.sub ), noun )

        el.setState({
            adjective: adj,
            noun: noun.toLowerCase()
        })
    }

    randomizeOne ( what ) {
        const arr    = el.props[what],
              rnd    = Math.round( Math.random() * ( arr.length - 1 ) ),
              subrnd = Math.round( Math.random() * ( arr[rnd].items.length - 1 ) )

        return {
            top: rnd,
            sub: subrnd
        }
    }

    format ( what, how ) {
        const end = how.substr(-1)

        switch ( end ) {
            case 'а':
            case 'я':
            case 'ь':
                return this.deepFormat( what, 'w' )
            case 'о':
            case 'е':
                return this.deepFormat( what, 'u' )
            default:
                return this.deepFormat( what, 'm' )
        }
    }

    deepFormat ( what, how ) {
        let end = what.substr( -2 )
        return what.replace( end, el.getEnd( how, end ) )
    }

    getEnd ( how, end ) {
        let rep = {
            w: {
                'ый': 'ая',
                'ий': 'ая',
                'ой': 'ая'
            },
            u: {
                'ый': 'ое',
                'ий': 'ее',
                'ой': 'ое'
            },
            m: {
                'ая': 'ый'
            }
        }

        return ( rep[how][end])
            ? rep[how][end]
            : end
    }

    getSome ( what, top, sub ) {
        return this.props[what + 's'][top].items[sub].item
    }

    render () {
        let phrase = this.state.adjective + ' ' + this.state.noun

        return (
            <div className="some-words">
                <h1>{phrase} <FavButton item={phrase} /></h1>
                <Button onClick={this.randomize}>Еще раз</Button>
            </div>
        )
    }
}

Variant.propTypes = {
    adjectives: PropTypes.array,
    nouns: PropTypes.array
}

export default Variant
