const enums = {}

enums.woods = [
    'wood',
    'pine',
    'larch',
    'oak',
    'beech',
    'venge'
]

enums.materials = [
    ...enums.woods,
    'jewresin',
    'colorings',
    'metalfurniture',
    'silverfurniture',
    'leathercord',
    'natural'
]

enums.collections = {
    'ready': 'https://www.livemaster.ru/olyaki/section/786191-v-nalichii',
    'pendant': 'https://www.livemaster.ru/olyaki/section/786181-kulony',
    'ring': 'https://www.livemaster.ru/olyaki/section/786195-koltsa',
    'double': 'https://www.livemaster.ru/olyaki/section/786195-koltsa',
    'jewresin': 'https://www.livemaster.ru/olyaki/section/786197-s-yuvelirnoj-smoloj',
    'wood': 'https://www.livemaster.ru/olyaki/section/786199-s-derevom'
}

enums.types    = [ 'ring', 'pendant', 'double' ]
enums.states   = [ 'ready', 'example', 'byorder' ]
enums.technics = [ 'resinart' ]

enums.multi    = [
    'natural',
    'colorings',
    'metalfurniture',
    'silverfurniture'
]

export default enums
