import React, { Component } from 'react'

import './center.scss'

class Center extends Component {
    render () {
        return (
            <div className = "align-center">
                <div className = "center-wrap">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default Center
