import { format } from 'tools'

const superState = {}

superState.append = {
    is: el =>
        {
            return ( state ) => {
                return Object.values( el.state[state] ).includes( true )
            }
        },

    update: el =>
        {
            return ( data ) => {
                let newState = Object.assign( {}, el.state )

                Object.keys( data ).map( key => {
                    if ( newState[key] !== undefined ) {
                        if ( typeof newState[key] === 'object' && typeof data[key] !== 'object' ) {
                            Object.keys ( newState[key] ).map( sub => {
                                newState[key][sub] = data[key]
                            })
                        } else {
                            newState[key] = data[key]
                        }
                    } else {
                        if ( key.indexOf( '-' ) > -1 ) {
                            let split = key.trim().split( '-' ),
                                top = split[0],
                                sub = split[1]

                            newState[top][sub] = data[key]
                        }
                    }
                })

                el.setState( newState )
            }
        },
deepUpdate: el =>
    {
        return ( sub, data, add ) => {
            let upd = {}

            Object.keys( data ).map( key => {
                upd[key + '-' + sub] = data[key]
            })

            el.update({ ...upd, ...add })
        }
    }
}

superState.init = ( cmp, data ) =>
    {
        cmp.state = format.extract( data, 'vars' )

        Object.keys( superState.append ).map( name => {
            cmp[name] = superState.append[name]( cmp )
        })
    }

export default superState
